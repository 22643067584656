import React from 'react'
import { Row, Col } from 'antd'
import { connect } from 'react-redux'
import ReactPlayer from 'react-player'

const mapStateToProps = ({ virtualEvent }) => ({ virtualEvent })

@connect(mapStateToProps)
class PlayArea extends React.Component {

  render() {
    const {
      virtualEvent: { thePlaylist,currentVideoinPlaylist },
    } = this.props

    let currentVideo = thePlaylist[currentVideoinPlaylist]
    if(thePlaylist === {} || currentVideoinPlaylist === null){
      currentVideo = {
        url: ""
      }
    }
    return (
      <div>
        <Row>
          <Col span={24} className="text-center">
            <ReactPlayer
              url={currentVideo.url}
              playing
              controls
              width="100vh"
              height="56.25vh"
              loop
              // onEnded={this.handleClose}
              volume={1}
              style={{ backgroundColor: '#181818', borderRadius: "0 15px 0 0" }}
            />
          </Col>
        </Row>
      </div>
    )
  }
}

export default PlayArea