import React from 'react'
import { Layout } from 'antd'
import { Scrollbars } from 'react-custom-scrollbars'
import styles from './style.module.scss'

class LoginLayout extends React.PureComponent {
  render() {
    const { children } = this.props
    return (
      <Layout>
        <Layout.Content>
          <div className={styles.container}>
            <div className={styles.left}>
              <Scrollbars style={{ height: '100vh' }}>
                <div className={`${styles.headerlayout} text-center`}>
                  <img
                    src="resources/images/logo-inverse-home.png"
                    alt="Wiz365 Logo"
                    className={styles.brandlogo}
                  />
                </div>
                <div className={styles.mainlayout}>
                  <section>{children}</section>
                </div>
                {/* <div className={`${styles.footer} text-center`}>
                  <img
                    src="resources/images/presentedby.png"
                    alt="Sponsor Logos"
                    className={styles.sponsorlogo}
                  />
                </div> */}
              </Scrollbars>
            </div>
            <div
              className={styles.right}
              style={{ backgroundImage: 'url("resources/images/login-bg.jpg")' }}
            />
          </div>
        </Layout.Content>
      </Layout>
    )
  }
}

export default LoginLayout
