import React from 'react'
import { 
  // Avatar,
  Card, 
  Row, 
  Col } from 'antd'
// import styles from '../style.module.scss'
import './speakerPreview.less'

/* eslint-disable prefer-promise-reject-errors, prefer-template */

class SpeakerPreview extends React.Component {

  render() {
    // const { currentIndexOption, currentIndexColor } = this.state
    const {
        speakerData
    } = this.props

    console.log("inside preview")
    console.log(speakerData)
    return (
      <div>
        <Card className="speakerPreviewDesign">
          <Row justify="space-around" align="middle">
            <Col span={24}>
              <img src={speakerData.imageUrl} style={{ width:"100%",borderRadius: '10px 10px 0px 0px' }} alt={speakerData.speakerName} />
              {/* <Avatar
                src={speakerData.imageUrl}
                border
                borderColor="white"
                shape="square"
                size={150}
                
              /> */}
            </Col>
          </Row>
          <br />
          <Row className="bioSpeak">
            <Col span={24}>
              <h3>{speakerData.speakerName}</h3>
              <h5>{speakerData.smallIntro}</h5>
            </Col>
          </Row>
        </Card>

      </div>
    )
  }
}

export default SpeakerPreview
