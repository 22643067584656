import React from 'react'
import { Row, Col } from 'antd'
import { connect } from 'react-redux'
// import { push } from 'react-router-redux'
import actions from 'redux/virtual/event/actions'
import SpeakerInfo from '../SpeakerInfo'
// import { BellFilled, PlayCircleFilled } from '@ant-design/icons'

import './speaker.less'

/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions, jsx-a11y/no-noninteractive-element-interactions */
const mapStateToProps = ({ router,virtualEvent }) => ({ router,virtualEvent })
@connect(mapStateToProps)
class SpeakerList extends React.Component {
  initiateSession = event => {
    const { dispatch } = this.props
    const id = event.target.getAttribute('data-id')
    const scene = event.target.getAttribute('data-room')
    const url = event.target.getAttribute('data-url')
    dispatch({
      type: 'virtualSettings/CHANGE_SETTING',
      payload: {
        setting: 'isAgendaOpen',
        value: false,
      },
    })
    dispatch({
      type: actions.FILTER_SCENE,
      payload: {
        id: scene,
        navigated: true,
        componentRendered: false,
        sessionID: id,
        currentSessionURL: url,
      },
    })
    // dispatch(push(`${pathname}?scene=${scene}&session=${id}`))
  }

  openDetailedBio = (event) => {
    const { dispatch } = this.props
    console.log("Clicking Speakers")
    console.log(event.target.getAttribute('data-speakerid'))
    dispatch({
      type: actions.SET_STATE,
      payload: {
        currentSpeakerID: event.target.getAttribute('data-speakerid'),
        isSpeakerBioOpen: true
      },
    })
  }

  getSpeakerItem = item => {
    // const { isVirtualMobileView } = this.props
    return (
      <>
        {/* <Popover content={<SpeakerInfo speakerData={item} />}> */}
        <Col xs={12} md={6} lg={6} xl={6} className="speakerDetails text-center">
          <Row gutter={0} className="speakerImage" justify="space-around" align="middle">
            <Col span={24}>
              <img src={item[1].imageUrl} data-speakerid={item[0]} style={{cursor:"pointer"}} className="thespeakerImage" alt={item[1].speakerName} onClick={this.openDetailedBio} />
            </Col>
          </Row>
          <Row>
            <Col span={24} className="text-center speakerName">
              <b>
                <h5>{item[1].speakerName}</h5>
              </b>
            </Col>
          </Row>
          <Row>
            <Col span={24} className="text-center speakerIntro">
              <h7>{item[1].smallIntro}</h7>
            </Col>
          </Row>
        </Col>
        {/* </Popover> */}
      </>
    )
  }

  render() {
    const { speakers,virtualEvent:{currentSpeakerID} } = this.props
    const showcase = Object.entries(speakers).map(key => {
      return this.getSpeakerItem(key)
    })
    return (
      <div>

        {currentSpeakerID !== '' ?
          <SpeakerInfo speakers={speakers} />
          :
          ''
        }
        <Row gutter={0} className="speakerContainer" justify="space-around">
          {showcase}
        </Row>
      </div>
    )
  }
}

export default SpeakerList
