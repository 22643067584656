import React from 'react'
import { Row, Col, Modal } from 'antd'
import { CloseCircleOutlined } from '@ant-design/icons'
import { Scrollbars } from 'react-custom-scrollbars'
// import { push } from 'react-router-redux'
// import actions from 'redux/virtual/event/actions'
import { connect } from 'react-redux'
import actions from 'redux/virtual/event/actions'
import Preview from './Preview'

// import './miniprofile.less'

@connect(({ virtualEvent }) => ({ virtualEvent }))
class SpeakerInfo extends React.Component {
  closeDetailedBio = () => {
    const { dispatch } = this.props
    dispatch({
      type: actions.SET_STATE,
      payload: {
        isSpeakerBioOpen: false,
        currentSpeakerID: ''
      },
    })
  }

  render() {
    const { speakers,virtualEvent:{isSpeakerBioOpen,currentSpeakerID} } = this.props

    console.log("Inside speakerinfo")
    console.log(currentSpeakerID)
    console.log(speakers)
    console.log(speakers[currentSpeakerID])
    return (
      <Modal
        visible={isSpeakerBioOpen}
        style={{
          top: 0,
          // display: 'flex',
          maxHeight: '100vh',
        }}
        wrapClassName="speakerModal"
        maskClosable
        mask
        width="60rem"
        onOk={this.closeDetailedBio}
        confirmLoading
        onCancel={this.closeDetailedBio}
        footer={null}
        closeIcon={<CloseCircleOutlined style={{ fontSize: '25px' }} />}
        maskStyle={{backgroundColor: "rgba(15, 12, 41, 0.7)"}}
        destroyOnClose
      >
        <Scrollbars autoHeight autoHeightMin="100px" autoHeightMax="calc(100vh - 15rem)">
          <Row gutter={16}>
            <Col xs={24} md={8} lg={10} xl={10} className="text-center">
              <Preview speakerData={speakers[currentSpeakerID]} />
            </Col>
            <Col xs={24} md={16} lg={14} xl={14} style={{margin: "15px auto"}}>
              <div dangerouslySetInnerHTML={{ __html: speakers[currentSpeakerID].detailedIntro }} />
              {/* <p>{ he.decode(speakers[currentSpeakerID].detailedIntro) }</p> */}
            </Col>
          </Row>
        </Scrollbars>
      </Modal>
    )
  }
}

export default SpeakerInfo
