import React from 'react'
import { Row, Col, Card } from 'antd'
import { connect } from 'react-redux'
import actions from 'redux/virtual/event/actions'
import './sidebar.less'

const mapStateToProps = ({ virtualEvent }) => ({ virtualEvent })

@connect(mapStateToProps)
class Sidebar extends React.Component {
  selectVideo = (theVideo) => {
    const { dispatch } = this.props
    dispatch({
      type: actions.SET_STATE,
      payload: {
        currentVideoinPlaylist: theVideo
      },
    })
  }

  render() {
    const {
      virtualEvent: { thePlaylist,currentVideoinPlaylist },
    } = this.props
    const listData = [];
    Object.entries(thePlaylist).map((key) => {
      const theClasses = currentVideoinPlaylist === key[0] ? "videocard videocardselected" : "videocard"
      const textClasses = currentVideoinPlaylist === key[0] ? "white-text" : ""
      listData.push(
        <Card bordered={false} className={theClasses} key={key[0]} style={{ width: "100%" }} onClick={() => {this.selectVideo(key[0])}}>
          <Row>
            <Col span={24}>
              <h5><b className={textClasses}>{key[1].heading}</b></h5>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <p className={textClasses}>{key[1].description}</p>
            </Col>
          </Row>
        </Card>
      )
      return ""
    });

    return (
      <div className="sidebarInternal">
        <Row>
          <Col span={24}>
            <h5 className="text-center" style={{padding: "10px"}}><b>Click on the video you would like to watch</b></h5>
            {listData}
            {/* <List
              itemLayout="vertical"
              size="small"
              dataSource={listData}
              renderItem={item => (
                <List.Item
                  key={item.type}
                  style={{
                    cursor: "pointer"
                  }}
                  data-videokey={item.key}
                  onClick={() => {this.selectVideo(item.key)}}
                >
                  <List.Item.Meta
                    title={<b className="white-text">{item.heading}</b>}
                  />
                  <p className="white-text">{item.description}</p>
                </List.Item>
              )}
            /> */}
          </Col>
        </Row>
      </div>
    )
  }
}

export default Sidebar