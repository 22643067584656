import React from 'react'
import { Modal, Row, Col, Tooltip,Empty } from 'antd'
import { connect } from 'react-redux'
import { CloseCircleTwoTone } from '@ant-design/icons'
import { Scrollbars } from 'react-custom-scrollbars'
import actions from 'redux/virtual/event/actions'
import Sidebar from './LayoutComponents/Sidebar'
import PlayArea from './LayoutComponents/PlayArea'

import './videoplaylist.less'

const mapStateToProps = ({ virtualEvent }) => ({ virtualEvent })

@connect(mapStateToProps)
class VideoPlaylist extends React.Component {

  handleClose = () => {
    const { dispatch } = this.props
    dispatch({
      type: actions.SET_STATE,
      payload: {
        thePlaylist: {},
        playlistActive: false,
        currentVideoinPlaylist: null
      },
    })
  }

  render() {
    const {
      virtualEvent: { playlistActive,currentVideoinPlaylist },
    } = this.props
    return (
      <div>
        <Modal
          visible={playlistActive}
          style={{ maxHeight: '100vh' }}
          wrapClassName="playlistModal"
          maskClosable={false}
          mask
          centered
          width="126vh"
          onOk={this.handleClose}
          confirmLoading
          onCancel={this.handleClose}
          footer={null}
          maskStyle={{backgroundColor: "rgba(15, 12, 41, 0.95)"}}
          closeIcon={<Tooltip title="Close Tutorial Video"><CloseCircleTwoTone twoToneColor="#eb2f96" style={{ fontSize: '25px' }} /></Tooltip>}
          destroyOnClose
        >
          <Row id="modalContent" className="modalContent">
            <Col span={5} className="sidebar">
              <Scrollbars>
                <Sidebar />
              </Scrollbars>
            </Col>
            <Col span={19} className="mainContent">
              <Row className="mainDirectory">
                <Col span={24}>
                  {currentVideoinPlaylist === null
                  ?
                    <Empty description={<p className="white-text">Select a video to play.</p>} style={{marginTop: "10%"}} />
                  :
                    <PlayArea />
                  }
                </Col>
              </Row>
            </Col>
          </Row>
        </Modal>
      </div>
    )
  }
}

export default VideoPlaylist