import axios from 'axios'

/* eslint-disable no-unused-vars */
const superlocalURL = `https://localhost:443/api/`
const semiProductionURL = `https://2574a8503b79.ngrok.io/api/`
const productionURL = `https://virtual.wiz365.io/api/`
const metroproductURL = `https://metro.wiz365.io/api`
export default axios.create({
  baseURL: metroproductURL,
})
