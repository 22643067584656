export async function getLeftMenuData(isVirtualMobileView,navigationData,isExhibitor) {
  let isLogout = ''
  if(isVirtualMobileView)
  {
    isLogout = {
      title: 'Logout',
      key: 'logout',
      icon: 'icmn icmn-switch',
    }
  }
  let thefinalLeg = {}
  if (isExhibitor) {
    thefinalLeg = {
      title: 'Leads',
      key: 'leads',
      icon: 'icmn icmn-user-check',
    }
  }

  // let theDirectory = {}
  // if (!isExhibitor) {
  //   theDirectory = {
  //     title: 'Directory',
  //     key: 'directory',
  //     icon: 'icmn icmn-book',
  //   }
  // }

  console.log("is logout?")
  console.log(isLogout)

  return [
    {
      title: 'Back',
      key: 'back',
      icon: 'icmn icmn-undo2',
    },
    {
      title: 'Home',
      key: 'home',
      icon: 'icmn icmn-home',
    },
    {
      title: 'Agenda',
      key: 'agenda',
      icon: 'icmn icmn-calendar',
    },
    {
      title: 'Navigation',
      key: 'navigation',
      icon: 'icmn icmn-compass2',
      children: navigationData,
    },
    {
      title: 'Chat',
      key: 'sidebar',
      icon: 'icmn icmn-bubbles2',
    },
    // {
    //   title: 'Briefcase',
    //   key: 'briefcase',
    //   icon: 'icmn icmn-briefcase',
    // },

    {
      title: 'Helpdesk',
      key: 'helpdesk',
      icon: 'icmn icmn-info',
    },
    // {
    //   title: 'My Ideas',
    //   key: 'visitedstalls',
    //   icon: 'icmn icmn-bookmarks',
    // },
    // {
    //   title: 'My Points',
    //   key: 'pointredeem',
    //   icon: 'icmn icmn-coin-dollar',
    // },
    {
      title: 'Speakers',
      key: 'speakers',
      icon: 'icmn icmn-man',
    },
    // {
    //   title: 'Meetings',
    //   key: 'meeting',
    //   icon: 'icmn icmn-user-tie',
    // },
    // theDirectory,
    isLogout,
    thefinalLeg,
    
  ]
}
export async function getTopMenuData(isVirtualMobileView,navigationData,isExhibitor) {
  console.log("in menu top")
  console.log(isVirtualMobileView)
  let isLogout = ''
  if(isVirtualMobileView)
  {
    isLogout = {
      title: 'Logout',
      key: 'logout',
      icon: 'icmn icmn-switch',
    }
  }
  let thefinalLeg = {}
  if (isExhibitor) {
    thefinalLeg = {
      title: 'Leads',
      key: 'leads',
      icon: 'icmn icmn-user-check',
    }
  }

  // let theDirectory = {}
  // if (!isExhibitor) {
  //   theDirectory = {
  //     title: 'Directory',
  //     key: 'directory',
  //     icon: 'icmn icmn-book',
  //   }
  // }
  return [
    {
      title: 'Back',
      key: 'back',
      icon: 'icmn icmn-undo2',
    },
    {
      title: 'Home',
      key: 'home',
      icon: 'icmn icmn-home',
    },
    {
      title: 'Agenda',
      key: 'agenda',
      icon: 'icmn icmn-calendar',
    },
    {
      title: 'Navigation',
      key: 'navigation',
      icon: 'icmn icmn-compass2',
      children: navigationData,
    },
    {
      title: 'Chat',
      key: 'sidebar',
      icon: 'icmn icmn-bubbles2',
    },
    // {
    //   title: 'Briefcase',
    //   key: 'briefcase',
    //   icon: 'icmn icmn-briefcase',
    // },

    {
      title: 'Helpdesk',
      key: 'helpdesk',
      icon: 'icmn icmn-info',
    },
    // {
    //   title: 'My Ideas',
    //   key: 'visitedstalls',
    //   icon: 'icmn icmn-bookmarks',
    // },
    // {
    //   title: 'My Points',
    //   key: 'pointredeem',
    //   icon: 'icmn icmn-coin-dollar',
    // },
    // {
    //   title: 'Rome',
    //   key: 'networkingZone',
    //   icon: 'icmn icmn-user-tie',
    //   url: "/virtual/main?scene=7c08f4-306-25c2-0288-a8fde52d4c8&mode=navigation"
    // },
    // {
    //   title: 'Milan',
    //   key: 'networkingZone',
    //   icon: 'icmn icmn-user-tie',
    //   url: "/virtual/main?scene=88e6f-8378-f8fc-054-cc2d055a6370&mode=navigation"
    // },
    // {
    //   title: 'Venice',
    //   key: 'networkingZone',
    //   icon: 'icmn icmn-user-tie',
    //   url: "/virtual/main?scene=6187b74-75e-e74a-51a-bdc13685bc&mode=navigation"
    // },
    {
      title: 'Speakers',
      key: 'speakers',
      icon: 'icmn icmn-man',
    },
    // {
    //   title: 'Meetings',
    //   key: 'meeting',
    //   icon: 'icmn icmn-user-tie',
    // },
    // Object.keys(theDirectory).length !== 0 ? theDirectory : '',
    thefinalLeg,
    isLogout,
  ]
}
